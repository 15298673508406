import { Menu } from '../typings/menu';
import { ModuleIcons } from './icons.config';
export const PrimaryMenu: Menu[] = [
  {
    id: 'home',
    label: 'Home',
    route: './home',
    icon: ModuleIcons.home,
    disabled: false,
  },
  {
    id: 'assets',
    label: 'Assets',
    route: './assets/map',
    icon: ModuleIcons.assets,
    disabled: false,
    subMenu: [
      {
        id: 'assets',
        label: 'Inspector',
        route: './assets/map',
        icon: ModuleIcons.inspector,
        disabled: false,
      },
      {
        id: 'utilisation',
        label: 'Utilisation',
        route: './utilisation',
        icon: ModuleIcons.utilisation,
        disabled: false,
      },
      {
        id: 'servicing',
        label: 'Servicing',
        route: './servicing',
        icon: ModuleIcons.servicing,
        disabled: false,
      },
    ],
  },

  {
    id: 'search',
    label: 'Search',
    route: './search',
    icon: ModuleIcons.search,
    disabled: true,
  },
  {
    id: 'dashboards',
    label: 'Dashboards',
    route: './dashboards',
    icon: ModuleIcons.dashboards,
    disabled: false,
  },
  {
    id: 'work-orders',
    label: 'Operations',
    route: './work-orders',
    icon: ModuleIcons.operations,
    disabled: false,
    subMenu: [
      {
        id: 'work-orders',
        label: 'Work Orders',
        route: './work-orders',
        icon: ModuleIcons.workOrders,
        disabled: false,
      },

      {
        id: 'transport',
        label: 'Transport',
        route: './transport',
        icon: ModuleIcons.transport,
        disabled: false,
      },
      {
        id: 'people',
        label: 'People',
        route: './people',
        icon: ModuleIcons.people,
        disabled: false,
      },
    ],
  },
  {
    id: 'reports',
    label: 'Reports',
    route: './reports',
    icon: ModuleIcons.reports,
    disabled: false,
  },

  {
    id: 'data-explorer',
    label: 'Data Explorer',
    route: './data-explorer',
    icon: ModuleIcons.dataExplorer,
    disabled: false,
  },
  {
    id: 'groups',
    label: 'Groups',
    route: './groups',
    icon: ModuleIcons.assetGroups,
    disabled: false,
  },
  {
    id: 'geofences',
    label: 'Geofences',
    route: './geofences',
    icon: ModuleIcons.geofenceManagement,
    disabled: false,
  },
  {
    id: 'workflows',
    label: 'Workflows',
    route: './workflows',
    icon: ModuleIcons.workflowManagement,
    disabled: false,
  },
  {
    id: 'admin',
    label: 'Settings',
    route: './admin',
    icon: ModuleIcons.settings,
    disabled: false,
    subMenu: [
      {
        id: 'admin',
        label: 'Admin',
        route: './admin',
        icon: ModuleIcons.admin,
        disabled: false,
      },
      {
        id: 'connectors',
        label: 'Connectors',
        route: './connectors',
        icon: ModuleIcons.connectors,
        disabled: false,
      },
      {
        id: 'inventory',
        label: 'Inventory',
        route: './inventory',
        icon: ModuleIcons.inventory,
        disabled: false,
      },
    ],
  },
];

export const AdditionalMenu: Menu[] = [];
